.milestone-back-color {
    background-color: #F2F4F7;
    min-height: 100vh;
}

.milestone-breadcrumb {
    width: 235px;

    .milestone-breadcrumb-name p {
        color: #1B1F4159;
    }

    .milestone-breadcrumb-name.active p {
        color: #1B1F41;
    }

    .milestone-breadcrumb-arrow {
        img {
            transform: rotateY(180deg)();
        }
    }
}

.milestone-head-one {
    background-color: #1B1F410D;
    border-radius: 5px;

    .milestone-inspection-one {
        padding-top: 13px;
        margin-bottom: 12px;
        padding-left: 26px;
        max-width: 60%;

        p {
            font-size: 14px;
            line-height: 17px;
            color: #1B1F4180;
            font-family: "Circular Std", sans-serif;
        }

        img {
            margin-bottom: 2px;
            margin-right: 2px;
        }
    }

    .milestone-inspection-two {
        padding-top: 23px;
        padding-right: 22px;
    }

    .btn-milestone-view-footage {
        border: 1px solid #112B57;
        border-radius: 100px;
        margin-right: 8px;
        height: 27px;
        font-size: 10px;
        font-weight: 450;
        margin-bottom: 5px;
    }

    .blue-background {
        background-color: #1B1F41;
    }

    .btn-milestone-follow-up {
        height: 39px;
        border: 1px solid #1B1F41;
        font-size: 12px;
        line-height: 15.18px;
        color: #1B1F41;
    }

    .btn-milestone-confirm {
        height: 39px;
        font-size: 12px;
        line-height: 15.18px;
        background-color: #1B1F4126;
        color: #112B57;
    }

    .milestone-confirm {
        background-color: #1B1F41;
        color: white;
    }
}

.milestone-head-two {
    background-color: #FFFFFFE5;
    margin-top: 24px;
    border-radius: 5px;
    height: 96px;
    border: 1px;
    padding-left: 35px;
    padding-right: 28px;

    p {
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
        text-align: left;
        color: #1B1F4180;
    }

    .milestone-head-title {
        border-right: 1px solid #1B1F4126;
        padding-right: 25px;
    }

    .milestone-title {
        font-family: "Circular Std", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 25.3px;
        text-align: left;
    }

    .milestone-sub-contractor {
        border-radius: 6px;
        background-color: #F6F3FC;
        color: #1B1F41;
        font-family: "Circular Std", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.71px;
        text-align: left;
        padding: 7px 10px 4px;

        img {
            margin-right: 10px;
            vertical-align: text-top;
            width: 10.31px;
            height: 11.79px;
        }

        p {
            font-family: "Circular Std", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.71px;
            text-align: left;
            color: #1B1F41;
            margin-bottom: 0px;

        }
    }

    .milestone-gps-verification {
        font-family: "Circular Std", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.71px;
        text-align: left;
        border-right: 1px solid #1B1F4126;
        padding-left: 35px;

        img {
            height: 25px;
            width: 25.5px;
            margin-right: 7px;
        }
    }

    .milestone-status {
        padding-left: 31px;
    }

    .btn-milestone-status {
        background-color: #FF8A0026;
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
        text-align: left;

    }
}

.milestone-details-two {
    margin-top: 33px;

    .milestone-footage-details {
        padding-right: 50px;
    }

    .milestone-footage {
        background-color: #ffff;
        height: 341px;
        margin-bottom: 18px;
        border-radius: 16px;
        position: relative;

        >span {
            position: absolute;
            top: 10px;
            left: 15px;
            color: white;
            font-size: 14px;
        }

        >button {
            position: absolute;
            top: 45%;
            left: 47%;
            cursor: pointer;
            background: none;
            border: none;
            z-index: 999;

            img {
                height: 30px;
                width: 30px;
            }
        }

        video {
            border-radius: 16px;
        }

        .full-inspection-video {
            video {
                border-radius: 16px;
            }
        }

        .video-error {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .active-non-conformities {
        background-color: #ffff;
        margin-bottom: 18px;
        border-radius: 16px;
        position: relative;

        button {
            background: none;
            border: none;

            img {
                height: 20px;
                width: 20px;
            }
        }

        .stretch-button {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 111;
        }

        .close-button {
            position: absolute;
            top: 20px;
            right: 10px;
        }

        img {
            width: 100%;
            height: 420px;
            border-radius: 16px 16px 0px 0px;
        }

        .fullscreen-section {
            position: relative;
            display: flex;
            justify-content: center;

            .image-fullscreen {
                border-radius: 0px;
                height: 100vh;
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .milestone-description {
        background-color: #FFFFFFE5;
        padding: 30px;
        border-radius: 16px;

        h6 {
            font-family: "Circular Std", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.24px;
            text-align: left;
            color: #1B1F41;

        }

        .milestone-description-heading {
            font-family: "Circular Std", sans-serif;
            font-size: 10px;
            font-weight: 500;
            line-height: 12.65px;
            letter-spacing: 0.15em;
            text-align: left;
            margin-top: 26px;
        }
    }

    .milestone-paragraph {
        border-radius: 10px;
        border: 1px solid #1B1F4126;

        .milestone-paragraph-one {
            margin-bottom: 0;
            padding: 22px;
            padding-top: 17px;
            font-family: "Circular Std", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 22.4px;
            text-align: left;
            color: #1B1F41CC;
        }

    }

    .milestone-inspection {
        background-color: #FFFFFF;
        border-radius: 10px;
        height: 67px;

        .milestone-full-inspection-title {
            font-family: "Circular Std", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.24px;
            text-align: left;
            margin-bottom: 0px;
        }

        .milestone-current-footage-thumbnail {
            background-color: #1B1F418C;
            height: 51px;
            top: 338px;
            left: 1349px;
            gap: 0px;
            border-radius: 5px 0px 0px 0px;
            opacity: 0px;
        }

        p {
            font-family: "Circular Std", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.24px;
            text-align: left;
            color: #1B1F41;
        }
    }

    .milestone-non-confirmities-title {
        font-family: "Circular Std", sans-serif;
        font-size: 12.4px;
        font-weight: 500;
        line-height: 15.69px;
        text-align: left;
        margin-top: 30px;
        color: #112B57;

    }

    .milestone-non-confirmities-none {
        background-color: #FFFFFFB2;
        height: 86px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-family: "Circular Std", sans-serif;
            font-size: 12.4px;
            font-weight: 500;
            line-height: 15.69px;
            text-align: left;
            color: #112B57;
            margin-bottom: 0px;
        }
    }

    .milestone-have-non-confirmities {
        background-color: #ffff;
        margin-top: 18px;
        padding: 18px;
        border-radius: 16px;

        .milestone-non-confirmities-title {
            font-family: "Circular Std", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.24px;
            text-align: left;
            margin-top: 0px;
        }

        .milestone-non-confirmities-description {
            font-family: "Circular Std", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            color: #1B1F4180;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }

        .btn-milestone-extra-work {
            background-color: #FF101040;
            width: 83px;
            height: 25px;
            padding: 4px 6px 4px 6px;
            border-radius: 6px;
            font-family: "Circular Std", sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.18px;
            text-align: center;
        }

        .btn-milestone-extra-confirm {
            background-color: #1B1F41;
            height: 37px;
            color: #ffff;
            font-family: "Circular Std", sans-serif;
            font-size: 10px;
            font-weight: 500;
            line-height: 12.65px;
            text-align: left;
            padding: 6px 10px;
            opacity: 1 !important;
        }

        .btn-milestone-extra-deny {
            background-color: #1B1F410D;
            height: 37px;
            border: 1px solid #1B1F4126;
            border-radius: 6px;
            font-family: "Circular Std", sans-serif;
            font-size: 10px;
            font-weight: 500;
            line-height: 12.65px;
            text-align: left;
        }

        .non-conformities-image {
            position: relative;
            width: 60px;
            height: 40px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }

            .vector {
                position: absolute;
                top: 35%;
                left: 30%;
                width: 24px;
                height: 16px;
            }
        }

    }

    .active-activity {
        border: 1.5px solid #1B1F4180;
    }

    .extra-work-denied {
        background-color: #FFFFFFE5;
        padding: 30px;
        border-radius: 16px;
        margin-top: 22px;

        .milestone-deny-accept-name {
            height: 34px;
            padding: 9px 13px 8px 13px;
            border-radius: 55px;
            background-color: #1B1F410D;

            p {
                font-family: "Circular Std", sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 17.71px;
                text-align: left;

            }
        }

        .milestone-confirm {
            background-color: #1B1F41;
            color: white;
        }

        .deny-accept-date {
            p {
                font-family: "Circular Std", sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 17.71px;
                text-align: left;
            }
        }

        .milestone-deny-accept-desc {
            margin-top: 9px;
            margin-left: 35px;

            p {
                font-family: "Circular Std", sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 22.4px;
                text-align: left;
                color: #1B1F41CC;
            }
        }
    }

}

.milestone-heading {
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.71px;
    text-align: left;
    color: #1B1F41;
}

.milestone-table {

    table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 32px;
        position: relative;
        border-collapse: separate;
        border-spacing: 0 10px;
    }


    th {
        text-align: left !important;
        font-family: "Circular Std", sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 13.91px;
        color: #00000080;
        padding: 8px;
    }

    tr {
        margin-bottom: 10px;
    }

    td {
        text-align: left !important;
        font-family: "Circular Std", sans-serif;
        font-size: 14.56px;
        font-weight: 500;
        line-height: 18.42px;
        color: #1b1f41;
        padding: 15px 8px;
    }

    .milestone-row-details {
        width: 1094px;
        height: 50px;
        border-radius: 10px;
        background-color: #FFFFFF59;

    }

    .milestone-relation {
        background-color: #F6F3FC;
        width: 76px;
        height: 26px;
        border-radius: 6px;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 12.65px;
        text-align: left;
    }

    .milestone-planned-date {
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
        text-align: left;
        color: #1B1F41;
    }

    .milestone-name {
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
        text-align: left;
        color: #000000;
    }

    .milestone-done-btn {
        width: 53px;
        height: 30px;
        padding: 8px 13px 8px 13px;
        border-radius: 5px;
        background-color: #7BFDD6;
        font-family: "Circular Std", sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 13.91px;
        text-align: left;
    }

    .milestone-overdue-btn {
        width: 53px;
        width: 70px;
        height: 30px;
        padding: 8px 13px 8px 13px;
        border-radius: 5px;
        background-color: #FDBE9B;
        font-family: "Circular Std", sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 13.91px;
        text-align: left;
    }

    .milestone-planned-btn {
        width: 67px;
        height: 30px;
        padding: 8px 13px 8px 13px;
        border-radius: 5px;
        background-color: #D5D5D5;
        font-family: "Circular Std", sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 13.91px;
        text-align: left;
    }

    .milestone-zero-non-conformities {
        width: 20.8px;
        height: 20.8px;
        padding: 2px 6px;
        border-radius: 6.24px;
        background-color: #D5EBD2;
        font-family: "Circular Std", sans-serif;
        font-size: 14.56px;
        font-weight: 500;
        line-height: 18.42px;
        color: #1B1F41;
        display: block;
    }

    .milestone-one-non-conformities {
        width: 20.8px;
        height: 20.8px;
        padding: 2px 6px;
        border-radius: 6.24px;
        background-color: #F7D0D0;
        font-family: "Circular Std", sans-serif;
        font-size: 14.56px;
        font-weight: 500;
        line-height: 18.42px;
        text-align: center;
        color: #1B1F41;
        display: block;
    }
}