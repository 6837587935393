.replan-followup-modal {
    padding: 22px;
}

.replan-followup-modal-body {



    h4 {
        font-family: "Circular Std", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: #1B1F41;
    }

    p {
        font-family: "Circular Std", sans-serif;
        font-size: 14px;
        font-weight: 450;
        line-height: 22.4px;
        text-align: left;
        color: #000000;
    }

    .replan-followup-description {
        textarea {
            margin-top: 12px;
            margin-bottom: 23px;
            width: 434px;
            height: 92px;
            border-radius: 7.2px;
            border: 1px solid #1B1F4126;
        }

    }

}


.replan-followup-modal-footer {
    .btn-proceed-to-planning {
        width: 210px;
        height: 46px;
        padding: 12px 16px 12px 16px;
        border-radius: 6px;
        background-color: #1B1F41;
        color: #ffffff;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 12.65px;
    }

    .btn-proceed-to-planning-cancel {
        width: 210px;
        height: 46px;
        padding: 12px 16px 12px 16px;
        border-radius: 6px;
        background-color: #1B1F410D;
        color: #1B1F41;
        border: 1px solid #1B1F4126;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 12.65px;
    }

}