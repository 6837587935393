.drop-file-input {
    position: relative;
    /* width: 400px; */
    height: 200px;
    border: 2px dashed #384EB74D;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #F8F8FF;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    margin-top: 26px;
    text-align: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;

    .drop-file-input__label_msg {
        font-family: Mulish, sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: #676767;
        margin-bottom: 20px;
    }
}



.drop-file-input__label img {
    width: 100px;
}

.drop-file-preview {
    margin-top: 30px;
}



.drop-file-preview__title {
    font-family: Mulish, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
    margin-bottom: 10px;
}

.form-select {
    height: 42px;
    font-size: 14px;
    font-family: 'Circular Std', sans-serif;
    color: #292828;
    font-weight: normal;
    border: 1px solid #1B1F4126;
    border-radius: 7.2px;
}

.form-select:focus {
    border-color: #dddee3;
    outline: 0;
    box-shadow: none;
}

.pl-20 {
    padding-left: 50px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 8px;
    background-color: var(--input-bg);
    border-radius: 20px;
}

.drop-file-preview__item img {
    width: 29px;
}

.drop-file-preview__item__info {
    border: 1px solid #11AF22;
    border-radius: 3.55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    input {
        font-family: Mulish, sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        margin-bottom: 0;
        text-align: left;
        padding: 7px 7px 7px 10px;
        color: #0F0F0F;
        &:focus{
            outline: none;
        }
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
}
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 52%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    /* opacity: 0; */
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}

.drag-drop {
    padding: 25px 27px 31px 27px;
}

.drag-drop-file {
    font-family: Mulish, sans-serif;
    font-size: 14.2px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #0F0F0F;
    margin-top: 18px;
    margin-bottom: 6px;

    span {
        color: #483EA8;
        text-decoration: underline;
    }
}

.start-date {
    width: 100%;
    text-align: left !important;
}

.textarea-height {
    height: 92px;
}