.Pre-inspection-box {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  padding: 15px;
  border-radius: 10px;
  min-width: 262px;
  background-color: #fff;
  border: 1px solid #1B1F411A;

  /*&:last-child{
     margin-right: 20px;
  }*/
  .required {
    font-family: "Circular Std", sans-serif;
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0.15em;
    text-align: left;
    margin: 0px;
  }
  h3 {
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1b1f41;
    margin-bottom: 10px;
  }
  .Geconfigureerd {
    background: #2be58c26;
    font-family: "Circular Std", sans-serif;
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
    padding: 5px 10px;
    margin: 0px;
    border-radius: 6.02px;
  }
  .Pre-inspection-inner {
    display: flex;
    justify-content: space-between;
  }

  .name-profile {
    font-family: "Circular Std", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B1F41;
    margin-bottom: 0px;
  }
}

.Pre-inspection-box.active{
    border: 1px solid #1B1F41 
}

.add-new-milestone {
    min-width: 135px;
    border: 1px dashed #1B1F4126;
    border-radius: 6.02px;
    background: #FAFBFB;
    padding: 7px 9px;
    color: #1B1F4180;
    font-family: "Circular Std", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    img{
        margin-left: 5px;
    }
}

.mt-54{
    margin-top: 54px;
    position: relative;
    min-width: 100%;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 49px;
        border-top: 0.85px dashed #1B1F41;
        width: 100%;
        z-index: -1;
    }
}
.configure-milestone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    border-bottom: 1px solid #1B1F411A;
    padding-bottom: 20px;
    margin-bottom: 27px;
    h3{
        font-family: "Circular Std", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #1B1F41;
        }
}
.configuremilestone-text{
font-family: "Circular Std", sans-serif;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: #1B1F4180;
margin-bottom: 10px;
}

.pre-inspection{
    padding:8px 16px 8px 16px;
    border-radius: 6px;
    background-color: #2BE58C26;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
    margin-left: 13px;
}
.criterea{
font-family: "Circular Std", sans-serif;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: #1B1F41;
img{
    margin-left: 5px;
}
}

.mb-24{
    margin-bottom: 24px;
}

.upload-file{
    border: 1px solid #000000;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #112B57;
    padding: 12px;
    border-radius: 6px;
    background-color: #fff;
    height: 46px;
    width: 25%;
}
.Pre-inspection{
    margin-top: 50px;
    padding: 20px;
    background-color: #2122320D;
    border-radius: 7.07px;
    font-family: "Circular Std", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    height: 59px;
    border:none;
    width: 100%;
}

.textarea-h{
    height: 100%;
}
.instruction{
    border: 0.98px solid #1B1F4126;
    border-radius: 7.07px;
    height: 100%;
    width: 100%;
}
.required-text{
    font-family: "Circular Std", sans-serif;
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0.15em;
    text-align: left;
    color:#1B1F4180;
    position: relative;
    top: -1px;

}
.relative{
    position: relative;
}

.lock-right{
    position: absolute;
    right: 11px;
    top: 44px;
}

.leftmanage{
    top: 35px;
    left: 3px;
}

.finalize-btn{
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 12px 16px 12px 16px;
    color: #112B57;
    background-color: #fff;
    opacity: 0.5;
    border: 1px solid #1B1F41;
    border-radius: 6px;
    img{
        vertical-align: text-bottom;
        margin-right: 10px;
        margin-bottom: 2px;
    }
}

.finalize-btn-active{
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 12px 16px 12px 16px;
    color: #ffff;
    background-color:#1B1F41;
    opacity: 1;
    border: 1px solid #1B1F41;
    border-radius: 6px;
}

.dark-inspection{
    &::placeholder{
    font-weight: 800;
    }
    }

.scroll-lines {
    display: flex;
    overflow-x: auto;
    padding-bottom:18px;
    align-items: center;
    gap: 55px;
    justify-content: space-between;
}   
    
.single-pointtab{
    border: 1px solid #1B1F4126;
    border-radius: 10px;
    padding: 6px;
    width: 100%;
    margin-top: 20px;

    .btn-lg{
        font-family: "Circular Std", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;
        text-align: center;
        border-radius: 6px;
        height: 38px;
        border: none;
        width: 50%;
    }
    .btn-lg.active{
        background: #1B1F41B2;
        border-color: #1B1F41B2;
        color: #fff;
    }
}

.top-space{
    margin-top: 34px;
}

.scroll-lines::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    background-color: transparent;
}

/* Track */
.scroll-lines::-webkit-scrollbar-track {
    background: transparent;
}
/* Handle */
.scroll-lines::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 100px;
}
.scroll-lines:hover::-webkit-scrollbar-thumb {
    background: #1B1F41;
    border-radius: 100px;
    transition: background 0.5s ease; 
}

.scroll-lines:hover::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    transition: background 0.5s ease;
}

.Te-configureren{
    background: #1B1F410D!important;

}

.upload-milestone-file {
    padding: 7px;
    /*margin-top: -45px;*/
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 118px;
}

.configuew-next-btn{
    cursor: pointer;
    border: none;
    background: transparent;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.18px;
    text-align: left;
    img{
        margin-left: 5px;
    }
}

.upload-files-multi {
    border: 1px solid #EAEBEF;
    border-radius: 6px;
    background: #FBFBFD;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.18px;
    text-align: left;
    padding: 12px;
    width: 100%;
    color: #1B1F4180;
    margin-bottom: 9px;
    position: relative;

input{
    border: none;
    background-color: transparent;
}
span{
    img{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0px;
        margin-top: -5px;
    }
}

}

.w-80{
    width: 80%;
}