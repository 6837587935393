.deliverable-header {
    background-color: #1B1F410D;

    .deliverable-on-timeline {
        margin-left: 24px;

        p {
            font-family: "Circular Std", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.24px;
            text-align: left;
            color: #000000;

        }
    }

    p {
        font-family: "Circular Std", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.24px;
        text-align: left;

    }

    .deliverable-to-confirm {
        background-color: #bebfc8;
        width: 196px;
        height: 39px;
        border-radius: 6px;
        opacity: 0.25px;
        margin: 15px;
        color: #ffff;
        text-align: center;
        border: none;
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
    }
    .enable-button{
        background-color: #1B1F41;
    }
}

.deliverable-list {
    margin-top: 60px;
    background-color: #1B1F4108;
}

.deliverable {
    border: 1px solid #1B1F41;
    border-radius: 6px;
    margin: 8px 31px 8px 31px;
    width: 200px;
    padding: 10px;
    margin-bottom: 16px;
    background-color: #ffff;

    .lock-plain {
        margin-bottom: 3px;
        margin-right: 3px;
    }

    h4 {
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
        text-align: left;
        color: #112B57;
    }

    p {
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #1B1F4180;
    }
}