@font-face {
  font-family: 'Circular Std Book';
  src: url('../../assests/font/CircularStd-Book.woff2') format('woff2'),
    url('../../assests/font/CircularStd-Book.woff') format('woff'),
    url('../../assests/font/CircularStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularStd-BoldItalic.woff2') format('woff2'),
    url('../../assests/font/CircularStd-BoldItalic.woff') format('woff'),
    url('../../assests/font/CircularStd-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('../../assests/font/CircularStd-BookItalic.woff2') format('woff2'),
    url('../../assests/font/CircularStd-BookItalic.woff') format('woff'),
    url('../../assests/font/CircularStd-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularStd-Bold.woff2') format('woff2'),
    url('../../assests/font/CircularStd-Bold.woff') format('woff'),
    url('../../assests/font/CircularStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularStd-MediumItalic.woff2') format('woff2'),
    url('../../assests/font/CircularStd-MediumItalic.woff') format('woff'),
    url('../../assests/font/CircularStd-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularStd-Black.woff2') format('woff2'),
    url('../../assests/font/CircularStd-Black.woff') format('woff'),
    url('../../assests/font/CircularStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularSpotifyText-Light.woff2') format('woff2'),
    url('../../assests/font/CircularSpotifyText-Light.woff') format('woff'),
    url('../../assests/font/CircularSpotifyText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularStd-BlackItalic.woff2') format('woff2'),
    url('../../assests/font/CircularStd-BlackItalic.woff') format('woff'),
    url('../../assests/font/CircularStd-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularSpotifyText-LightItalic.woff2') format('woff2'),
    url('../../assests/font/CircularSpotifyText-LightItalic.woff') format('woff'),
    url('../../assests/font/CircularSpotifyText-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../assests/font/CircularStd-Medium.woff2') format('woff2'),
    url('../../assests/font/CircularStd-Medium.woff') format('woff'),
    url('../../assests/font/CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aksara Bali Galang';
  src: url('../../assests/font/AksaraBaliGalang-Regular.woff2') format('woff2'),
    url('../../assests/font/AksaraBaliGalang-Regular.woff') format('woff'),
    url('../../assests/font/AksaraBaliGalang-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Muslish font */

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-Black.woff2') format('woff2'),
    url('../../assests/font/Mulish-Black.woff') format('woff'),
    url('../../assests/font/Mulish-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-BoldItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-BoldItalic.woff') format('woff'),
    url('../../assests/font/Mulish-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-ExtraBoldItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-ExtraBoldItalic.woff') format('woff'),
    url('../../assests/font/Mulish-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-BlackItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-BlackItalic.woff') format('woff'),
    url('../../assests/font/Mulish-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-ExtraBold.woff2') format('woff2'),
    url('../../assests/font/Mulish-ExtraBold.woff') format('woff'),
    url('../../assests/font/Mulish-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-Bold.woff2') format('woff2'),
    url('../../assests/font/Mulish-Bold.woff') format('woff'),
    url('../../assests/font/Mulish-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-MediumItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-MediumItalic.woff') format('woff'),
    url('../../assests/font/Mulish-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-Medium.woff2') format('woff2'),
    url('../../assests/font/Mulish-Medium.woff') format('woff'),
    url('../../assests/font/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-Italic.woff2') format('woff2'),
    url('../../assests/font/Mulish-Italic.woff') format('woff'),
    url('../../assests/font/Mulish-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-ExtraLightItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-ExtraLightItalic.woff') format('woff'),
    url('../../assests/font/Mulish-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-LightItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-LightItalic.woff') format('woff'),
    url('../../assests/font/Mulish-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-Regular.woff2') format('woff2'),
    url('../../assests/font/Mulish-Regular.woff') format('woff'),
    url('../../assests/font/Mulish-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-Light.woff2') format('woff2'),
    url('../../assests/font/Mulish-Light.woff') format('woff'),
    url('../../assests/font/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-ExtraLight.woff2') format('woff2'),
    url('../../assests/font/Mulish-ExtraLight.woff') format('woff'),
    url('../../assests/font/Mulish-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-SemiBold.woff2') format('woff2'),
    url('../../assests/font/Mulish-SemiBold.woff') format('woff'),
    url('../../assests/font/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../assests/font/Mulish-SemiBoldItalic.woff2') format('woff2'),
    url('../../assests/font/Mulish-SemiBoldItalic.woff') format('woff'),
    url('../../assests/font/Mulish-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: 'Circular Std', sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

.sidebar {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  width: 268px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #1B1F41;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0px;
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link img {
  margin-right: 12px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #BBBCC6;
  transition: 0.3s;
  background: #1b1f41;
  padding: 15px 15px 15px;
  line-height: 26px;

}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: #ffffff;
}

.sidebar-nav .nav-link.collapsed {
  color: #BBBCC6;
  background-color: #22264B;
  border-radius: 5px;
}

.cover-ul {
  background-color: #22264B !important;
}

.sidebar-nav .nav-link:hover {
  color: #fff;
  background: #22264B;
}

.submenus {
  background-color: #22264B;
  margin-top: 0 !important;
  border-radius: 0px 0px 5px 5px;
}

.submenus li:hover a span {
  color: #fff;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 5px 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}

.logo-left {
  padding: 27px 0px 23px;
}

.welkom {
  background: #0000001A;
  border-radius: 5px;
  padding: 10px 0px 0 0;
  text-align: center;
  margin-bottom: 36px;
}

.profile-image {
  width: 26px;
  height: 26px;
  background-color: #6FFCBA;
  border-radius: 100px;
  margin-right: 10px;
}

.flex-welkom {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 18px;
}

.name-profile {
  font-family: 'Circular Std',sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  color: #fff;
}

.welkom p {
  font-family: 'Circular Std',sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 0px 0px 6px 6px;
  padding: 5px;
  background: #242744;
  color: #fff;
  height: 27px;
  padding-left: 20px;
}

.top-part {
  width: 100%;
}

.fit-navbar-bottom {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 68px;
}

.top-line {
  border-top: 1px solid #6D7085;
  padding: 36px 0 0 0;
  margin-top: 36px;
}

.setting-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #181C3A;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 268px;
  height: 60px;
}

.setting-bottom p {
  padding: 0px 24px;
  margin: 0px;
  color: #fff;
  font-family: 'Circular Std', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.profile-circle-dot {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: #CD151C;
  border-radius: 50px;
  margin-right: 7px;
  vertical-align: sub;
}

#main {
  padding: 50px 30px 30px 74px;
  transition: all 0.3s;
  position: relative;
}

.pagetitle {
  margin-bottom: 10px;
  text-align: left;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
  font-family: 'Circular Std', sans-serif;
}

.breadcrumb {
  font-size: 14px;
  font-family: 'Circular Std', sans-serif;
  color: #899bbd;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  li span{
    margin: 0px 10px;
  }
}

li.breadcrumb-item {
  padding: 0px 8px 0px 0px;
  font-family: 'Circular Std', sans-serif;
}

.breadcrumb .active {
  color: #000;
  font-weight: 600;
}

.breadcrumb a {
  transition: 0.3s;
  font-family: 'Circular Std', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000080;
}

.sidebar-nav .nav-content.submenus a {
  padding: 10px 0 10px 20px;
  color: #BBBCC6;
}

.coming-soon {
  font-family: 'Circular Std', sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: #1B1F41;
  background: #84FFC4;
  border-radius: 2px;
  padding: 4px;
  display: block;
  margin: 0px 0px 0 10px;
}


.page-content {
  margin-top: 62px;
}

.page-title {
  font-family: 'Circular Std', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.tab-container {
  display: flex;
  gap: 18px;
  margin-top: 30px;
  margin-bottom: 30px;

  .btn {
    font-family: 'Circular Std', sans-serif;
    color: #b8b8b8;
    background: #ffffff;
    border: solid 1px #b8b8b8;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px 14px 8px 14px;
    height: 34px;
    border-radius: 24px;



  }

  .btn.active {
    color: #ffffff;
    background: #1B1F41;
    border: solid 1px rgba(27, 31, 65, 1);
  }
}

.configure-project-btn {
  background: #1B1F41;
  border: solid 1px rgba(27, 31, 65, 1);
  border-radius: 6px;
  height: 50px;
  font-family: 'Circular Std', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.configure-project-btn:hover {
  opacity: 0.9;
  background: #1B1F41;
  border: solid 1px rgba(27, 31, 65, 1);
}

.btn:focus-visible {
  background-color: #1b1f41;
  border-color: #1b1f41;
}

.box-primary {
  background: #fff;
  border: 1px solid #1B1F411A;
  border-radius: 10px;
  padding: 42px 34px 42px 34px;
  box-shadow: 0px 4px 25px 0px #2533AE0D;


  label {
    font-family: 'Circular Std', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;
    align-items: center;
  }

}

.form-heading {
  font-size: 16px;
  font-family: 'Circular Std', sans-serif;
  color: rgba(0, 0, 0, 1);
}

.form-label {
  font-size: 14px;
  font-family: 'Circular Std', sans-serif;
  color: #000000;
}

.form-control {
  font-size: 14px;
  font-family: 'Circular Std', sans-serif;
  color: #292828;
  font-weight: normal;
  border: 1px solid #1B1F4126;
  border-radius: 7.2px;
  height: 42px;
}


.declaration-text {
  font-family: 'Aksara Bali Galang', sans-serif;
  color: #1B1F41;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
}

.configure-project {
  margin-top: 27px;
}

#declaration {
  min-width: 19px;
  min-height: 19px;
}

input[type="checkbox" i] {
  appearance: none;
  border: 1px solid #B1B1B1;
  background: #F8F8FF;
  border-radius: 4px;
}

input[type="checkbox" i]:checked {
  background: url('../image/tick-line.svg'), #1b1f41;
  background-repeat: no-repeat;
  background-size: 79%;
  background-position: 2px 3px;
}

.relative-select {
  position: relative;

  .form-control {
    position: relative;
    padding-left: 55px;
  }

  .form-select {
    position: relative;
    padding-left: 50px;
  }
}

.icon-select {
  position: absolute;
  top: 37px;
  left: 15px;
  width: 36px;
  height: 36px;
}

.number-to-map {
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 16px;

  span {
    background: #1014340D;
    border-radius: 6px;
    color: #000;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 48px;
    text-align: center;
    height: 36px;
    padding: 5px 0px;
  }
}

.form-control:focus {
  border-color: #dddee3;
  outline: 0;
  box-shadow: none;
}

.textarea-height {
  height: 92px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.skip-for {
  font-family: 'Circular Std', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000A6;
  text-decoration: underline;
}

.btn:active {
  background-color: #1b1f41 !important;
  border-color: #1b1f41 !important;
}

.create-btn-project {
  padding: 12px 16px 12px 16px;
  background: #1B1F41;
  border-radius: 6px;
  font-family: 'Circular Std', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  height: 39px;
  display: inline-block;
  margin-left: 25px;
  color: #ffff;
}

@media (min-width: 1200px) {
  #main {
    margin-left: 268px;
  }
}