.project-detail-address {
    font-family: "Circular Std", sans-serif !important;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #1B1F41 !important;
}

.font-lap{
    @media only screen and (min-width: 1200px) and (max-width:1340px) {
        font-size: 14px!important;
      }
}

.part-sub-project {
    .company-name {
        p {
            font-family: "Circular Std", sans-serif !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            line-height: 17.71px !important;
            text-align: left;
            color: #112B57 !important;
            padding-left: 17px;
        }
    }

}

.project-detail-enexis {
    p {
        font-family: "Circular Std", sans-serif !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 17.71px !important;
    }

    .mb-20-sapce {
        margin-bottom: 20px !important;
    }
}

.SubContractor-img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 8px;
}

.detail-attachment {
    margin-top: 39px !important;
}

.align-d {
    margin-top: 26px !important;
}

.all-milestones {
    max-width: 93%;
    margin-inline: auto;
    margin-top: 47px;

    h2 {
        font-family: "Circular Std", sans-serif;
        font-size: 18.72px;
        font-weight: 500;
        line-height: 23.68px;
        text-align: left;
        color: #000000;
    }

    .add-a-subproject {
        background: #1b1f41;
        padding: 12px 16px 12px 16px;
        border-radius: 6px;
        font-family: "Circular Std", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.18px;
        text-align: left;
        border: none;
        color: #fff;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 37px;
        position: relative;

        th {
            text-align: center !important;
            font-family: "Circular Std", sans-serif;
            font-size: 10.4px;
            font-weight: 500;
            line-height: 13.16px;
            color: #00000080;
            padding: 8px;
        }

        td {
            text-align: center !important;
            font-family: "Circular Std", sans-serif;
            font-size: 14.56px;
            font-weight: 500;
            line-height: 18.42px;
            color: #1b1f41;
            padding: 15px 8px;
        }

        .milestone-text-left {
            text-align: left !important;
        }

        .milestone-row{
            border-radius: 10px;
            box-shadow: 0px 4px 25px 0px #00000008;
        }

        .btn-completed {
            font-family: "Circular Std", sans-serif;
            font-size: 11.44px;
            font-weight: 500;
            line-height: 14.47px;
            text-align: center;
            color: #000;
            padding: 9px 5px;
            background-color: #7bfdd6;
            border-radius: 5.2px;
            display: block;
        }


        .non-confirmitive-btn {
            font-family: "Circular Std", sans-serif;
            font-size: 11.44px;
            font-weight: 500;
            line-height: 14.47px;
            text-align: center;
            color: #000000;
            padding: 9px 5px;
            background-color: #FD9B9B;
            border-radius: 5.2px;
            display: block;
        }

        .planned-btn {
            font-family: "Circular Std", sans-serif;
            font-size: 11.44px;
            font-weight: 500;
            line-height: 14.47px;
            text-align: center;
            color: #000000;
            padding: 9px 5px;
            background-color: #D5D5D5;
            border-radius: 5.2px;
            display: block;
        }



        .in-progress {
            background-color: #7bd6fd;
        }

        .project-btn-completed {
            font-family: "Circular Std", sans-serif;
            font-size: 11.44px;
            font-weight: 500;
            line-height: 14.47px;
            text-align: left;
            color: #000;
            padding: 9px 13px;
            background-color: #7bfdd6;
            border-radius: 5.2px;
        }

        .project-in-progress {
            background-color: #7bd6fd;
        }



        .non-confirmitive-milestones {
            background-color: #C6C7CF;

            padding: 5px 8px;
            border-radius: 6px;
            color: #ffff;
        }

        .confirmitive-milestones {
            background-color: #EA503D;
            padding: 5px 9px;
            border-radius: 6px;
            color: #ffff;
        }

        .dark-arrow {
            position: absolute;
            right: 0;
        }
    }

    .tab-buttons {
        border-bottom: 1px solid #0000001a;
        padding-bottom: 19px;

        button {
            border: none;
            background-color: #fff;
            font-family: "Circular Std", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.71px;
            text-align: left;
            margin-right: 80px;
            padding: 0;
            color: #1b1f41;
            opacity: 25%;
        }

        .active {
            opacity: 100%;
            position: relative;

            &::before {
                content: "";
                border: 1px solid #000;
                position: absolute;
                bottom: -20px;
                width: 100%;
            }
        }

        span {
            padding: 2px 6px 2px 6px;
            background-color: #1b1f41;
            font-family: "Circular Std", sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.18px;
            text-align: center;
            border-radius: 4.8px;
            color: #fff;
            margin-right: 9px;
            height: 18px;
            display: inline-block;
        }
    }
}

.margin-r-7{
    margin-right: 7px;
    position: relative;
    bottom: 2px;
}