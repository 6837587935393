.non-confirmities-modal {

    .modal-cross {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    .source-image{
        max-height: 250px;
        border-radius: 6px 6px 0px 0px;
    }
}

.non-confirmities-modal-body {
    padding: 20px;

    
    h4 {
        font-family: "Circular Std", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: #1B1F41;
    }

    p {
        font-family: "Circular Std", sans-serif;
        font-size: 14px;
        font-weight: 450;
        line-height: 22.4px;
        text-align: left;
        color: #000000;
    }

    .non-confirmities-description {
        textarea {
            margin-top: 12px;
            height: 92px;
            border-radius: 7.2px;
            border: 1px solid #1B1F4126;
        }

    }
}

.non-confirmities-modal-footer {
    padding: 0 20px;
    margin-bottom: 24px;

    .btn-non-confirmities-deny {
        height: 37px;
        padding: 12px 16px 12px 16px;
        border-radius: 6px;
        background-color: #1B1F41;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 12.65px;
        color: #FFFFFF;
    }

    .btn-non-confirmities-cancel {
        height: 37px;
        padding: 12px 16px 12px 16px;
        border: 1px solid #1B1F4126;
        border-radius: 6px;
        background-color: #1B1F4126;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 12.65px;
        color: #1B1F41;
    }
}