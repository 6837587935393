.project-map {
    padding: 74px;

    .drop-file-input {
        height: 375px;
        border-radius: 3.55px;
    }
}

.project-map-image {
    border: 1px solid #1B1F4126;
    border-radius: 16px;
    padding: 25px;
    text-align: center;
}

.image-upload-map {
    padding: 74px 0px 74px 100px;
    margin: 0px;

    .map-scale {
        h3 {
            font-family: 'Circular Std', sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
        }

        .number-to {
            width: 220px;
            height: 46px;

            .form-control {
                padding-left: 56px;
            }

            span {
                background: #1014340D;
                border-radius: 6px;
                color: #000;
                position: absolute;
                top: 3px;
                left: 3px;
                width: 48px;
                text-align: center;
                height: 36px;
                padding: 5px 0px;
            }

        }
    }
}


.uploaded-project-map {
    h3 {
        font-family: 'Circular Std', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 19px;
        padding-left: 30px;

    }

    .map-visible {
        border: 1px solid #10143426;
        padding: 34px;
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 16px;
    }
}

.map-section-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.skip-steps-right {
    margin-right: 35px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: underline;
    font-family: 'Circular Std', sans-serif;
}

.arrow-btns {
    button {
        width: 35px;
        height: 35px;
        border: none;
        border-radius: 12px;
        margin: 0px 4px;
        background-color: rgb(255 255 255 / 44%);
        backdrop-filter: blur(70.71px);

        .down-arrow {
            transform: rotate(180deg);
        }
    }

    img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7499%) hue-rotate(284deg) brightness(122%) contrast(99%);
    }

    .left-arrow {
        transform: rotate(269deg);
    }

    .right-arrow {
        transform: rotate(90deg);
    }

    .rotate-right-relaod {
        transform: rotate(-127deg);
    }
}

.switch-section {
    display: flex;
    align-items: center;
    height: 120px;

    label {
        margin-left: 30px;
        margin-right: 20px;
    }

}