.bg-map-image {
  background-image: url("../image/bg-map-image.png");
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  min-height: 300px;
}

.gm-fullscreen-control {
  background-color: gray !important;
  opacity: 0.85 !important;
  border-radius: 5px !important;
  background-image: url("../image/arrow-expand-04.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;

  img {
    display: none !important;
  }
}

.overview-box-top {
  padding-top: 0px;
  padding-left: 0px;
  position: relative;
  z-index: 0;

  .project-overview-box {
    width: fit-content;
    background: transparent;
    color: #ffffff;
    border: 1px solid rgba(27, 31, 65, 0.15);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    padding: 8px 14px 8px 14px;
    border-radius: 24px;
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin-bottom: 10px;

    height: 34px;
  }

  .overview-box-top-label {
    margin-top: 40px;

    img {
      margin-right: 0;
      vertical-align: baseline;
      transform: rotateY(180deg);
      padding: 0px 8px 0 8px;
    }
  }

}

.project-area-client {
  background-color: #fff;
  border: 1px solid #1b1f410d;
  box-shadow: 0px 10px 25px 0px #1b1f4108;
  max-width: 93%;
  margin-inline: auto;
  border-radius: 16px;
  margin-top: -54px;
  position: relative;
  z-index: auto;
  box-shadow: 0px 10px 25px 0px #1B1F4108;


  .rennovation-heading {
    h3 {
      font-family: "Circular Std", sans-serif;
      font-size: 11.5px;
      font-weight: 500;
      line-height: 15.18px;
      text-align: left;
      color: #1b1f4199;
      margin-bottom: 15px;

      @media only screen and (min-width: 1200px) and (max-width:1340px) {
        font-size: 10px;
      }

      @media only screen and (min-width: 1200px) and (max-width:1220px) {
        font-size: 9px;
      }

    }

    p {
      font-family: "Circular Std", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 28.8px;
      text-align: left;
      position: relative;
    }

  }

  .p-20 {
    padding: 20px;
  }

  .sub-contractor-lap {
    @media only screen and (min-width: 1200px) and (max-width:1340px) {
      font-size: 12px!important;
    }
  }

  .address-part {
    margin-top: 47px;
  }

  .Sub-contrators-part {
    padding: 0px 5px 0 0;
    overflow-x: auto;
    height: 182px;

    .company-name {
      border: .89px solid #112B5726;
      border-radius: 5px;
      margin: 0px 0 10px 0;
      height: 49px;
      display: flex;
      align-items: center;

      p {
        font-family: Mulish, sans-serif;
        margin-bottom: 0px;
        font-size: 10.65px;
        font-weight: 500;
        line-height: 15.98px;
        text-align: left;
        color: #0F0F0F;
      }

      img {
        margin-left: 14px;
        margin-right: 7px;
        vertical-align: text-bottom;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aab7cf;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #aab7cf;
    }

  }
}

.border-one {
  border-right: 1px solid #1b1f4126;
  padding: 20px;
}

.enexis {
  p {
    font-family: "Circular Std", sans-serif;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 17.71px;
    text-align: left;
  }
}

.in-progress {
  padding: 8.32px 13.52px 8.32px 13.52px;
  border-radius: 5.2px;
  opacity: 0px;
  font-family: "Circular Std", sans-serif;
  font-size: 11.44px;
  font-weight: 500;
  /* line-height: normal; */
  text-align: center;
  color: #000;
  background: #7bd6fd;
  border: none;
  height: 31.64px;
}

.btn-view-detail {
  padding: 8.32px 13.52px 8.32px 13.52px;
  border-radius: 5.2px;
  border: none;
  font-family: "Circular Std", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.71px;
  text-align: left;
}

.project-timeline {
  border: 1px solid #1b1f410d;
  box-shadow: 0px 4px 25px 0px #00000008;
  border-radius: 16px;
  max-width: 93%;
  margin-inline: auto;
  margin-top: 40px;
  padding: 26px 36px;
  display: flex;
  gap: 35px;


  h2 {
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #1b1f4199;
    margin-bottom: 25px;
  }

  p {
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.18px;
    text-align: left;
    margin-bottom: 7px;
    color: #112b5799;
  }

  .end-date {
    margin-top: 26px;
  }
}

.all-subprojects {
  max-width: 93%;
  margin-inline: auto;
  margin-top: 47px;

  h2 {
    font-family: "Circular Std", sans-serif;
    font-size: 18.72px;
    font-weight: 500;
    line-height: 23.68px;
    text-align: left;
    color: #000000;
  }

  .add-a-subproject {
    background: #1b1f41;
    padding: 12px 16px 12px 16px;
    border-radius: 6px;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.18px;
    text-align: left;
    border: none;
    color: #fff;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 37px;
    position: relative;

    .subproject-row {
      border-radius: 10px;
      box-shadow: 0px 4.16px 26px 0px #00000008;
    }

    th {
      font-family: "Circular Std", sans-serif;
      font-size: 10.4px;
      font-weight: 500;
      line-height: 13.16px;
      text-align: left;
      color: #00000080;
      padding: 8px;
    }

    td {
      font-family: "Circular Std", sans-serif;
      font-size: 14.56px;
      font-weight: 500;
      line-height: 18.42px;
      text-align: left;
      color: #000;
      padding: 15px 8px;
    }

    .btn-completed {
      font-family: "Circular Std", sans-serif;
      font-size: 11.44px;
      font-weight: 500;
      line-height: 14.47px;
      text-align: center;
      color: #000;
      padding: 9px 5px;
      background-color: #7bfdd6;
      border-radius: 5.2px;
      display: block;
    }

    .btn-in-progress {
      font-family: "Circular Std", sans-serif;
      font-size: 11.44px;
      font-weight: 500;
      line-height: 14.47px;
      text-align: center;
      color: #000;
      padding: 9px 5px;
      background-color: #7BD6FD;
      border-radius: 5.2px;
      display: block;
    }

    .planned-btn {
      font-family: "Circular Std", sans-serif;
      font-size: 11.44px;
      font-weight: 500;
      line-height: 14.47px;
      text-align: center;
      color: #000;
      padding: 9px 5px;
      background-color: #D5D5D5;
      border-radius: 5.2px;
      display: block;
    }

    .in-progress {
      background-color: #7bd6fd;
    }

    .project-btn-completed {
      font-family: "Circular Std", sans-serif;
      font-size: 11.44px;
      font-weight: 500;
      line-height: 14.47px;
      text-align: left;
      color: #000;
      padding: 9px 13px;
      background-color: #7bfdd6;
      border-radius: 5.2px;
    }

    .project-in-progress {
      background-color: #7bd6fd;
    }



    .total-milestones {
      background-color: #f6f7fb;
      padding: 5px 10px;
      border-radius: 6.24px;
      display: inline-block;


      .CircularProgressbar-path {
        stroke: #47DC4D !important;
      }

      .CircularProgressbar {
        height: 20px;
        margin-left: 2px;
        width: 20px;
        border-radius: 100%;

      }

      .CircularProgressbar-trail {
        stroke: #DDDFF1 !important;
      }
    }

    .dark-arrow {
      /*position: absolute;*/
      right: 0;
    }
  }

  .tab-buttons {
    border-bottom: 1px solid #0000001a;
    padding-bottom: 19px;

    button {
      border: none;
      background-color: #fff;
      font-family: "Circular Std", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.71px;
      text-align: left;
      margin-right: 80px;
      padding: 0;
      color: #1b1f41;
      opacity: 25%;
    }

    .active {
      opacity: 100%;
      position: relative;

      &::before {
        content: "";
        border: 1px solid #000;
        position: absolute;
        bottom: -20px;
        width: 100%;
      }
    }

    span {
      padding: 2px 6px 2px 6px;
      background-color: #1b1f41;
      font-family: "Circular Std", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15.18px;
      text-align: center;
      border-radius: 4.8px;
      color: #fff;
      margin-right: 9px;
      height: 18px;
      display: inline-block;
    }
  }
}

.attachment {
  margin-top: 25px;

  .none-attachment {
    height: 130px;
    padding: 0px 5px 0px 0px;
    background-image: url("../image/subProject-null.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .attachment-autoy.attachment-autoy {
    overflow-x: hidden;
    overflow-y: auto;
    height: 145px;
    padding: 0px 5px 0px 0px;

    .file-bg {
      background-color: #d0d7f4e5;
    }

    .input-file {
      /*width: 200px !important;*/
      background-color: #ffff !important;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aab7cf;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #aab7cf;
    }
  }

  p {
    font-family: Mulish, sans-serif;
    font-size: 10.65px !important;
    font-weight: 400;
    line-height: 15.98px;
    text-align: left;
    color: #0f0f0f;
    border: 0.89px solid #112b5726;
    padding: 10px 14px;
    margin: 0;
    border-radius: 5px;
    margin-bottom: 10px !important;
    height: 49px;
  }

  input {
    width: 89%;
    border: 0px;
    margin: 0px;
    padding: 0px;
    font-family: Mulish, sans-serif;
    font-size: 10.65px;
    font-weight: 500;
    line-height: 15.98px;
    text-align: left;
    color: #0F0F0F;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }

  img {
    margin-right: 7px;
  }


  .dropdown {
    position: absolute;
    right: 5px;

    button {
      padding: 0px;
      border: none;

      &:focus {
        background-color: transparent;
        border: none;
        box-shadow: none;
      }

      &:active {
        background-color: transparent !important;
        border: none;
        box-shadow: none;
      }
    }

    svg {
      fill: #1B1F4159;
    }

    .dropdown-item {
      padding-bottom: 5px;
      margin-bottom: 0;
      font-family: "Circular Std", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15.18px;
      text-align: center;

      &:active {
        background-color: transparent !important;
        color: #000 !important;
      }
    }
  }
}

.Description-text {
  margin-top: 18px;

  p {
    font-family: "Circular Std", sans-serif;
    font-size: 14px !important;
    font-weight: 450 !important;
    line-height: 24px !important;
    text-align: left;
    color: #000;
  }

  .float-end {
    background: #1B1F410D;
    border-radius: 6px;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding: 5px 10px;

    &:hover {
      border-color: #f3f4f5;
    }

    &:active {
      background: #1B1F410D !important;
      border-color: #f3f4f5 !important;
    }
  }

}

.Description-text textarea {
  background-color: #fff !important;
  border: none !important;
  font-family: "Circular Std", sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 24px;
  text-align: left;
  color: #000000;
  padding: 0 !important;

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aab7cf;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #aab7cf;
  }
}

.uploadfile {
  border: none;
  background-color: #1b1f41;
  font-family: "Circular Std", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.18px;
  text-align: center;
  color: #fff;
  padding: 12px 16px 12px 16px;
  border-radius: 5px;
  width: 100%;
}

.savefile {
  border: none;
  background-color: #036e0a;
  font-family: "Circular Std", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.18px;
  text-align: center;
  color: #fff;
  padding: 12px 16px 12px 16px;
  border-radius: 5px;
  width: 100%;
}

.dropdown-menu {
  box-shadow: 0px 4px 25px 2px #1B1F411A !important;
  border-radius: 10px !important;
  border-color: #cbcaca85;
  min-width: 110px;

  li {
    margin: 0px;
    padding: 0px;
    line-height: 20px;
  }

  a {
    padding-bottom: 0;
  }
}

.gm-ui-hover-effect {
  display: none !important
}

.gm-style-iw-tc {
  display: none !important
}

.gm-style-iw,
.gm-style-iw-c {
  background: #03C5D1 !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
  padding-bottom: 10px;
  padding-right: 10px;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
}

/*************** TIMELINE CHART ******************/
.timeline-chart-container {
  /**height: 300px;**/
  overflow: auto;
  border-left: 1px solid #1B1F4126;
}

.timeline-chart {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  background: #fff;
  padding: 0px 0px 0px 00px;

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aab7cf;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #aab7cf;
  }

  .subProject-null {
    position: relative;
    margin: auto;
    background-image: url("../image/subProject-null.svg");
    background-repeat: no-repeat;
    height: 175px;
    width: 245px;
  }

  .subProject-null-new-btn  {
    position: relative;
    button{
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 0;
    margin: auto;
    color: #fff;
    background-color: #1B1F41;
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.18px;
    text-align: center;
    padding: 12px 16px 12px 16px;
    width: fit-content;
    height: 39px;
    border-radius: 6px;

    }
  }


  .timeline-month-name {
    position: sticky;
    top: 0;
    font-family: "Circular Std", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color:#1B1F41;
    opacity: 50%;
    text-transform: uppercase;
    border-top: 1px solid #F4F4F6;
    margin-top: 17px;
    padding-top: 14px;
    margin-bottom: 19px;
  }

  .timeline-dates-in-month {
    display: flex;
  }

  .timeline-single-date {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50px;

    span {
      font-family: "Circular Std", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #1B1F414D;

      img {
        margin-right: 5px;
        height: 7px;
      }
    }

    .timeline-horizontal-line {
      /*height: calc(100vh - 200px);*/
      width: 2px;
      border-right: 0.56px solid #1B1F4199;
      opacity: 10%;
    }

    .timeline-today {
      /*height: calc(100vh - 200px);*/
      width: 2px;
      /* border-right: 2px dashed #03C5D1;*/
      background-image: url('../image/line-bottom.svg');
      background-size: 1.3px;
      background-repeat: repeat;
      position: relative;
      z-index: 9999;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 12px;
        height: 12px;
        background-color: #03c5d1;
        border-radius: 100px;
        left: -5px;
      }
    }

    .planned-icons {
      position: absolute;
      top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .time-line-subProject {
      background-color: #D0D7F4E5;
      text-align: center;
      border-radius: 10px;
      height: 25px;
      margin: auto;
      z-index: 999;
      padding: 5px;

      p {
        text-align: center;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .time-line-subProject-clicked {
      background-color: #333654;
      text-align: center;
      border-radius: 10px;
      height: 25px;
      margin: auto;
      z-index: 999;

      p {
        text-align: center;
        font-family: "Circular Std", sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #fff;
        padding-top: 5px;
        padding-left: 6px;
      }
    }

    .time-line-subProject-popup {
      position: absolute;
      background-color: #333654;
      width: 200px;
      z-index: 99999;
      border-radius: 10px;
      box-shadow: 0px 4px 25px 0px #00000008;
      border: 1px solid #1B1F410D;


      .time-line-subProject-popup-status {
        margin-top: 17px;
        padding-left: 17px;

        p {
          color: white;
          font-family: "Circular Std", sans-serif;
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;

          span {
            color:#000000;
            background-color: #7BFDD6;
            padding: 4px 8px;
            border-radius:5.2px;
            margin-left: 10px;
            font-size: 10px;
            font-weight: 500;
            line-height: 12.65px;
            text-align: left;
          }
        }

        .time-line-subProject-popup-info {
          margin-top: 14px;
          display: flex !important;
          gap: 18px;

          .time-line-subProject-popup-milestone {
            border: 5px;
            width: fit-content;
            background-color: #FFFFFF0D;
            color: #fff;
            text-align: center;
            padding: 5px 11px;
            line-height: 38px;
            font-size: 34px;
            font-weight: 500;
            border-radius: 6px;

            img {
              width: 15px
            }

            span {
              background-color: #333654;
              color: #FFFFFF80;
              font-weight: 500;
              font-size: 7px;
              line-height: 9px;
            }

            .CircularProgressbar-path {
              stroke: #47DC4D !important;
            }

            .CircularProgressbar {
              width: 20px;
              height: 20px;
              margin-left: 2px;
              vertical-align: inherit;
            }

            .CircularProgressbar-trail {
              stroke: black !important;
              opacity: .25;
            }

          }

          .time-line-subProject-popup-non-conformities {
            p {
              border: 5px;
              width: 36px;
              background-color: #FFFFFF0D;
              color: #fff;
              text-align: center;

              padding-top: 3px;
              line-height: 38px;
              font-size: 34px;
              font-weight: 500;
            }

            span {
              background-color: #333654;
              color: #FFFFFF80;
              font-weight: 500;
              font-size: 7px;
              line-height: 9px;
            }
          }
        }

        .time-line-subProject-link {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          float: right;
          padding-right: 10px;
          margin-bottom: 7px;
          font-family: "Circular Std", sans-serif;
        }
      }
    }
  }

  .timeline-subproject {
    background: #D0D7F4E5;
    border-radius: 1px;
    height: 25px;
  }

  &:first-child {
    /*margin-top: 87px;*/
  }

  img {
    vertical-align: inherit;
  }
}

body .date-font {
  font-family: "Circular Std", sans-serif;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 15.18px;
  text-align: left;

}

.milestones-font {
  font-family: "Circular Std", sans-serif;
  font-size: 7.71px !important;
  font-weight: 500;
  line-height: 9.75px;
  text-align: left;
  color: #FFFFFF80 !important;
  margin-top: 6.66px;
  margin-bottom: 10.34px;
}

.project-timeline .line-heights {
  line-height: 32px;
  color: #112B57;
  font-weight: 600;
  width: 118px;
  height: 45px;
  text-align: center;
}

.sub-in-created-btn {
  padding: 8.32px 13.52px 8.32px 13.52px;
  border-radius: 5.2px;
  background: #7BD6FD;
  font-family: "Circular Std", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  border: none;
  color: #000;
  height: 31.64px;

}

.line-height-manage {
  line-height: 19px !important;
}

.title-text {
  font-family: "Circular Std", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  color: #1B1F41;
}

.primary-confirm-btn {
  background: #1b1f41;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  font-family: "Circular Std", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.18px;
  text-align: left;
  border: none;
  color: #fff;
}

.primary-confirm-btn:hover {
  background: #1b1f41;
  color: #fff;
}

.primary-confirm-btn:focus {
  box-shadow: none;
}

.secondary-cancel-btn {
  border: 1px solid #1b1f41 !important;
  color: #1b1f41;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  font-family: "Circular Std", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.18px;
  text-align: left;
  border: none;
  background-color: #FFF;
}

.secondary-cancel-btn:hover {
  background-color: #FFF;
  color: #1b1f41;
}

.primary-confirm-btn:focus {
  box-shadow: none;
}

.ml-space {
  margin-left: 39px;
  margin-top: 40px;
}

.edit-input-file {
  font-family: Mulish, sans-serif;
  font-size: 10.65px !important;
  font-weight: 400;
  line-height: 15.98px;
  text-align: left;
  color: #0f0f0f;
  border: 0.89px solid #112b5726 !important;
  padding: 10px 14px !important;
  margin: 0;
  border-radius: 5px;
  margin-bottom: 10px !important;
  height: 49px;
}

.img-file-input {
  input {
    padding-left: 33px !important;
  }

  img {
    position: absolute;
    bottom: 16px;
    left: 13px;
  }
}

.arrow-breadcum {
  padding: 7px 0px !important;
  backdrop-filter: none !important;
  transform: rotateY(180deg);
  border: none !important;
}

.right-move {
  position: absolute;
  right: 8px;
  top: 25px;
}

.non-conformities {
  display: inline-block;
  width: 20.8px;
  height: 20.8px;
  min-height: 20.8px;
  padding: 1.04px 3.12px 1.04px 3.12px;
  font-family: "Circular Std", sans-serif;
  font-size: 14.56px;
  font-weight: 500;
  line-height: 18.42px;
  text-align: center;
  color: #1B1F41;
  border-radius: 6.24px;
  background-color: #f6f7fb;

}

.sub-status {
  min-width: 41px;
}

.align-center-image{
  text-align: center;
  margin: 0px auto;
  display: block;
}