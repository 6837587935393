.company-detail {
  border: 1px solid #1b1f411a;
  box-shadow: 0px 4px 25px 0px rgba(37, 51, 174, 0.05);
  padding: 36px 35px;
  border-radius: 6px;
  max-width: 515px;
  background-color: #fff;
  margin: 0px auto;

  h2 {
    font-family: "Circular Std", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  p {
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1b1f41b2;
  }

  .next-btn-slide {
    width: 100%;
    height: 48px;
    padding: 12px 0px 12px 0px;
    border-radius: 6px;
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    background: #1b1f41;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 9px;
      height: 9px !important;
    }
  }

  .icon-select {
    top: 36px;
  }

  .invitation-edit {
    width: 50px;
    height: 27px;
    top: 316px;
    left: 840px;
    gap: 0px;
    border-radius: 6px;
    border-color: #ffff;
    opacity: 0px;
    background-color:#1B1F410D;
  }
}

.background-slider {
  background: rgba(244, 246, 247, 1);
  border: 1px solid rgba(27, 31, 65, 0.1);
  margin-top: 35px;
  padding-top: 64px;

  .owl-stage {
    display: flex;
    align-items: center;
  }

  .owl-item {
    background-color: #fff;
    border: 1px solid #1b1f411a;
    box-shadow: 0px 4px 25px 0px rgba(37, 51, 174, 0.05);
    border-radius: 6px;
  }

  .owl-item.active {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: 0px 4px 25px 0px transparent;
  }

  .owl-nav {
    .owl-prev {
      position: relative;
      left: 0;
    }

    .owl-next {
      position: relative;
      right: 0;
    }
  }
}

.optional-text {
  color: #1b1f4126;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  z-index: 1;
  background-color: #ccc;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);

  .nav-btn {
    font-family: "Circular Std", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.71px;
    text-align: left;
    padding: 0px 13px;
  }
}

.owl-carousel .owl-nav button.owl-prev {
  left: 13%;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 15px;
    background-image: url(../image/left-Previous.svg);
    left: 0;
    background-size: 56%;
    background-repeat: no-repeat;
    background-position: 0px 2px;
  }
}

.owl-carousel .owl-nav button.owl-next {
  right: 13%;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 15px;
    background-image: url(../image/left-Previous.svg);
    right: 0;
    background-size: 56%;
    background-repeat: no-repeat;
    background-position: 0px 3px;
    transform: rotateY(188deg);
  }
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent !important;
  color: #1b1f41 !important;
}

.edit-btn {
  padding: 9px 14px 9px 14px;
  font-family: "Circular Std", sans-serif;
  font-size: 12px;
  font-weight: 450;
  line-height: 15.18px;
  text-align: left;
  color: #1B1F41;
  border-radius: 6px;
  margin-left: 13px;

}

.ck.ck-editor__main>.ck-editor__editable {
  height: 250px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: #dddee3 !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border-color: #dddee3 !important;
  box-shadow: none !important;
}


.placeholder-input::placeholder {
  color: #1B1F4180;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.71px;
  text-align: left;

}

.placeholder-input::-ms-input-placeholder {
  color: #1B1F4180;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.71px;
  text-align: left;
}

.invite-mail {
  width: 100%;
  height: 47px;
  padding: 11px 16px 11px 16px;
  border-radius: 6px;
  background: #1B1F41;
  border: none;
  font-family: "Circular Std", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.71px;
  text-align: center;
  color: #fff;

  img {
    padding: 0px 5px;
    vertical-align: text-bottom;
  }

}

.invite-via {
  background-color: #fff;
  border: 1px solid #112B57;
  border-radius: 6px;
  color: #112B57;

}

/*.item {
  transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 1;
}

.item.active {
  opacity: 1;
  transform: translateX(0%);
}*/