
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.selected-sub-contractors{
    margin-top: 50px;
    .heading-main{
    font-family: 'Circular Std',sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 18px;
    color: #000;
    }

    span{
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 1.04px 3.12px 1.04px 3.12px;
        background: #F8F8FF;
        font-family: 'Circular Std',sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        border-radius: 50px;
    }
    .yum-construction-out-part{
        background: #F8F8F9;
        border-radius: 10px;
        padding: 12px 16px 12px 16px;
    }
    .construction-btns{
        background: #1B1F411A;
        width: fit-content;
        height: 40.65px;
        border-radius: 10px;
        padding: 8px 16px 12px 16px;
        p{
            margin: 0px;
        }
    }
}

.top-50{
    margin-top: 50px;
}

.table-top{
 max-width: 100%;
 margin-top: 25px;
 border-collapse: collapse;
th{
    font-family: 'Circular Std',sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;  
   color: #00000080;
   border-bottom: 0px;
}

.border-highlight{
    border-radius: 6px;
    vertical-align: baseline;
    margin-top: 5px;
    margin-bottom: 15px;
    box-shadow: 0px 4px 25px 0px #2533AE0D;
    h3{
    font-family: 'Circular Std', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000080;
    border-bottom: 0px;
    margin: 12px 0px;
    }
}
td{
    border: 0px solid #1B1F411A;
    border-radius: 6px;
}
.yvm-td{
font-family: 'Circular Std',sans-serif;
font-size: 15px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: #1B1F41;
}
.verified{
    background: #1B1F4108;
    font-family: Outfit,sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #40404080;
    border-radius: 6px;
    display: block;
    height: 28px;
    padding: 8px 10px;
    width: fit-content;
  img{
    margin-right: 8px;
  }  
}
.groundwork{
font-family: 'Circular Std',sans-serif;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: #1B1F41;
}
.renovation{
    background: #1B1F410D;
    border-radius: 6px;
    display: block;
    width: fit-content;
    font-family: 'Circular Std',sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B1F41;
    min-height: 25px;
    padding: 6px 9px;
    img{
        width: 12px;
        height:12px;
        margin-right: 6px;
        vertical-align: text-bottom;
        filter: brightness(0) saturate(100%) invert(11%) sepia(64%) saturate(746%) hue-rotate(197deg) brightness(88%) contrast(95%);
    }
}
.bg-color-renovation{
    background: #1B1F4108;
    border-radius: 6px;
    display: inline-block;
    padding: 5px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        background: linear-gradient(270deg, #F2F4F6 0%, rgba(242, 243, 246, 0) 100%);
        width: 37px;
        right:1px;
        height:70%;
    }
}
.select-btn{
padding: 10px 16px 10px 16px;
border-radius: 6px;
background-color: #1B1F41;
border-color: #1B1F41;
width: 118px;
height: 35px;
font-family: 'Circular Std',sans-serif;
font-size: 10px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0.1px;
text-align: center;
}



}

.create-another-btn{
border-color: #1B1F411A;
font-family: 'Circular Std',sans-serif;
font-size: 15px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
background: #FFFFFF59;
color: #1B1F41;
padding: 16px;
border-radius: 6px;
width: 100%;
margin-bottom: 10px;
&:hover{
    background-color: #22264B;
    border-color: #1B1F411A;
}
}

.select-inline{
    margin-inline:auto;
    display: block;
}

.tab-change{
    width: 50px;
    height: 28px;
    border-radius: 5px;
    background: #1B1F410D;
    text-align: center;
    padding: 1px;
    margin-left: 8px;
    .Grid-box{
        width: 23px;
        height: 23px;
        background-color:#f3f4f5;
        display: inline-block;
        border-radius: 6px;
        line-height: 22px;
        cursor: pointer;
        }

    .active{
        background-color: #1B1F41;
        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(42deg) brightness(104%) contrast(102%);
        }
    }
}

.grid-flex{
    display: block!important;
}

.border-adjust{
    position: absolute;
    top: 0;right: 0;left: 0;bottom: 0;
    border: 1px solid #1B1F411A;
    border-radius: 10.4px;
    z-index: -1;
}

.all-sub-contractors{
        h3{
        font-family: 'Circular Std', sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        color: #00000080;
        border-bottom: 0px;
        margin: 0px;
        }
    }


.new-grid{
    border: 1px solid #1B1F411A;
    .bg-color-renovation{
    &::before{
        display: none;
    } 
    .renovation{
        width: 100%;
        margin-bottom: 5px;
    }
 }
}

.grid-25{
    margin-top: 25px;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    width:100%;
    border: 1px solid #1B1F411A;
    margin-bottom: 10px;
    border-radius: 10.4px;
    box-shadow: 0px 4px 25px 0px #2533AE0D;
    
}


.flex-row {
    display: flex;
    flex-grow: 1;
    width: 100%; /* Ensure rows take full width */
}

.flex-cell {
    width: 100%; /* Set a fixed width for each cell */
    padding: 10px;
    box-sizing: border-box;
        align-items:center;
        display:flex;

}
.th{
    font-family: 'Circular Std', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000080;
    border-bottom: 0px;
    }

    .active-tr-border{
        border: 1px solid #2BE58C;
        background: #2BE58C1A;
        .select-btn{
            background-color:  #2BE58C;
            border-color: #2BE58C;
        }
        .border-adjust{
            border: 1px solid #2BE58C;
            box-shadow: 0px 4.159999847412109px 26px 0px #00000008;
            background: #2BE58C1A;
            z-index: -1;
         }
    }
    .sub-contractors-heading{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
    h3{
        font-family: 'Circular Std', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color:#1B1F41;
    }
    .btn-sub-contractor{
        padding:12px 16px 12px 16px;
        font-family: 'Circular Std', sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        color:#fff;
        background-color: #1B1F41;
        border-radius: 6px;
      }
    }

    .number-text-background{
        padding: 1.04px 3.12px 1.04px 3.12px;
        background: #E5E7F359;
        color: #1B1F41;
        font-family: 'Circular Std', sans-serif;
        font-size: 15px!important;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .text-date{
    font-family: Circular Pro,sans-serif;
    font-size: 15px!important;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B1F41;

    }

    .item {
        position: relative;
        left: 0;
        right: 0;
        z-index: 9999;    
        margin: auto;
        width: 500px;
    }
    .own-nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        text-align: center;
        gap: 224px;
        z-index: 0;      
        
        button {
            height: 30px;
            width: 100px;
            border: none;
            background: transparent;
        }
        img{
            margin-bottom: 3px;
        }
        button:disabled {
            img {
                opacity: 0.3;
            }
        }
    }