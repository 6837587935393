.map-body {
    position: absolute;
    left: 0;
    width: 100%;
}


.black-ribbon {
    background: #101434;
    padding: 15px 41px 15px 75px;
    min-height: 73px;

    .form-heading {
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Circular Std', sans-serif;
    }

    .btn {
        border: 1px solid gray;
        color: #101434;
        background: #ffffff;
        font-weight: 500;
        font-family: 'Circular Std', sans-serif;
        padding: 12px 16px 12px 16px;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        height: 39px;
    }

    .btn:hover {
        opacity: 0.9;
    }

    button.btn.disabled {
        background: transparent;
        color: #8c8c8c;
    }
}

.map-container {
    position: relative;

    .map {
        height: 80vh;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .dark-overlay {
        background: linear-gradient(180deg, rgba(27, 31, 65, 0.5) 0%, rgba(27, 31, 65, 0) 100%);
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 100px;
        left: 0;
    }

    .search-field {
        top: 2rem;
        left: 2rem;
        display: flex;
        align-items: center;
        position: relative;
        backdrop-filter: blur(15px);
        width: fit-content;
        border-radius: 42px;
        background-color: #c7c6c52e;


        span {
            position: absolute;
            top: 13px;
            left: 1rem;
            color: #FFFFFF;
        }

        input {
            border-radius: 20px;
            width: 354px;
            height: 46px;
            padding-left: 3rem;
            outline: none;
            background: transparent;
            color: #FFFFFF;
            border: 1px solid rgba(27, 31, 65, 0.15);
            backdrop-filter: blur(15px);
        }

        input::placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;

        }
    }

    .map-area {
        position: absolute;
        bottom: 2rem;
        left: 2rem;
        border-radius: 20px;
        width: 357px;
        padding: 1rem 1.5rem;
        backdrop-filter: blur(20px);
        background: #FFFFFF26;

        .overflow {
            overflow-y: auto;
            max-height: 155px;
        }

        .show-error {
            border: 2px solid red;
        }

        .area-type {
            margin-top: 12px;
            margin-bottom: 14px;
            font-family: 'Circular Std', sans-serif;
            font-size: 13px;
            font-weight: 450;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;
            opacity: 0.5;
        }

        .yellow-line {
            width: 88%;
            height: 3px;
            background: #FCC331;
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .blue-line {
            background: #39e4d5;
        }

        .green-line {
            width: 88%;
            height: 3px;
            background: #2BD547 !important;
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            margin: auto;
        }


        .form-heading {
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            font-family: 'Circular Std', sans-serif;
            color: #fff;
            display: block;
            margin-top: 5px;

            img {
                margin-right: 10px;
                vertical-align: baseline;
            }
        }

        .form-label {
            color: #c9c3c3;
            font-size: 13px;
            font-weight: 450;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            font-family: 'Circular Std', sans-serif;
        }

        .text-yellow {
            color: #FCC331;
            margin-top: 31px;
            margin-bottom: 7px;
            display: inline-block !important;
        }

        .text-white {
            color: #ffff;
            margin-top: 31px;
            margin-bottom: 7px;
            display: inline-block !important;
        }

        .area-tabs {
            border: 1.29px solid #FFFFFF26;
            border-radius: 10px;
            padding: 6px;
            display: flex;

            .btn {
                color: #c9c3c3;
                font-size: 10px;
                font-weight: 500;
                font-family: 'Circular Std', sans-serif;
                border: none;
                min-width: 33.33%;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;

            }

            .btn.active {
                color: #ffffff;
                background: #FFFFFF26;
                border: none;
            }
        }

        .poi-input {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 10px;

            input {
                width: 100%;
                height: 41px;
                position: relative;
                padding-left: 5.6rem;
                outline: none;
                color: #ffffff;
                border: none;
                border-radius: 6px;
                font-size: 12px;
                pointer-events: none;
                background: #0000001a;
                opacity: 0.5;

            }

            .dot-circle {
                width: 7px;
                height: 7px;
                background-color: #FFFFFF;
                position: absolute;
                left: 55px;
                top: 3px;
                border-radius: 100px;
                opacity: 0.5;
            }

            .prefix {
                position: absolute;
                left: 1rem;
                z-index: 9;
                font-size: 10px;
                font-weight: 500;
                line-height: 13px;
                letter-spacing: 0em;
                text-align: left;
                color: #ffffff;
            }

            .btn {
                position: absolute;
                right: 0;
                font-size: 10px;
                color: #c9c3c3;
                outline: none;
                border: none;
            }

            .edit-btn {
                margin-right: 25px;
                font-size: 13px;
            }

            .delete-btn {
                font-size: 20px;
            }

            .enable-marker-btn {
                display: none;
            }

            .save-marker-btn {
                display: none;
            }

        }

        .poi-input.empty {
            .enable-marker-btn {
                display: block;
            }

            .edit-btn {
                display: none;
            }

            .delete-btn {
                display: none;
            }

            input {
                pointer-events: none;
            }
        }

        .poi-input.editable {
            .enable-marker-btn {
                display: none;
            }

            .edit-btn {
                display: none;
            }

            .delete-btn {
                display: none;
            }

            .save-marker-btn {
                display: block;
            }

            input {
                pointer-events: auto;
            }
        }

        .main-project-area {
            background: #FFFFFF1A;
            width: 100%;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 450;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
            opacity: 0.5;
            font-family: 'Circular Std', sans-serif;
            height: 41px;
            border-radius: 6px;

            .bi {
                margin-right: 5px;
            }

            &:focus {
                border: 0;
            }
        }
    }
}