.area-backdrop {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    height: 100% !important;
    z-index: 1;
}

.define-the-subproject {
    background: #FFFFFF26;
    border-radius: 24px;
    position: absolute;
    z-index: 3;
    width: 366px;
    padding: 33px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;

    .define-pera {
        font-family: 'Circular Std', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 20px;
    }

    .pera-text {
        font-family: 'Circular Std', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF99;
        margin-top: 24px;
    }
}

.start-btn {
    font-family: 'Circular Std', sans-serif;
    background: #1B1F41;
    padding: 8px 14px 8px 14px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
    display: block;
    height: 45px;
}

.Legend {
    top: 2rem;
    left: 2rem;
    display: block;
    align-items: center;
    position: relative;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    width: 131px;
    height: 85px;
    border-radius: 10px;
    padding-left: 13px;
    background-color: #c7c6c52e;

    .yellow-line {
        border: 2px solid #FCC331;
        width: 15px;
        height: 2px;
        display: block;
        border-radius: 2px;
        margin-right: 7px;
    }

    .blue-line {
        border: 2px solid #31FCE3;
        width: 15px;
        height: 2px;
        display: block;
        border-radius: 2px;
        margin-right: 7px;
    }

    .green-line {
        border: 2px solid #2BD547;
        width: 15px;
        height: 2px;
        display: block;
        border-radius: 2px;
        margin-right: 7px;
    }

    h3 {
        font-family: 'Circular Std', sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        padding-top: 10px;
        color: #fff;
    }

    p {
        font-family: 'Circular Std', sans-serif;
        font-size: 10px;
        font-weight: 450;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 10px;
        color: #fff;
    }

}

.green-line {
    background-color: #31FCE3 !important;
}

.subproject-green {
    color: #31FCE3 !important;
}

.map-zoom {
    margin-top: 20px;
    margin-right: 20px;
    z-index: 999;

    .zoom-btn {
        width: 49px;
        height: 49px;
        border-radius: 12px;
        -webkit-backdrop-filter: blur(20px);
        color: white;
        backdrop-filter: blur(20px);
        background: #FFFFFF26;
        margin: 10px;
        font-size: 32px;
        font-weight: 300;
        padding: 0;
    }
}

.sub-project-switch{
    margin-right: 20px;
    color: white;
    label{
        margin-right: 10px;
    }
}