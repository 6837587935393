.planing-bg {
  background: #1B1F4108;
}

.planing-top {
  background: #1B1F410D;
  color: #000000;
  display: flex;
  align-items: center;

  .form-heading {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

  }
}

.manage-left-plan {
  padding: 15px 0px 15px 75px;
  margin: 0;
  background: #1B1F4108;
}

.preperation-plan {
  border: 1px solid #1B1F41;
  border-radius: 6px;
  padding: 10px 15px;
  width: 201px;
  height: 64px;
  margin-bottom: 16px;
  background-color: #ffff;

  h3 {
    font-family: "Circular Std", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    margin-bottom: 11px;
  }

  p {
    margin: 0;
    font-family: "Circular Std", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B1F4180;
  }

  .planned {
    font-size: 10px;  
    color: #1B1F4180;
    img{
      margin-bottom: 1px;
      margin-right: 2px;
    }
  }
}

.preperation-plan.dragged {
  border: 2px dashed #ccc;
  opacity: 0.5;
}

.dragged-border {
  border: 1px solid #a2a2a3;
}

/*************** CUSTOM CHART ******************/
.gantt-chart-container {
  overflow: auto;
}

.gantt-chart {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  background: #fff;
  border-left: 1px solid #1B1F4126;
  padding: 30px 0px 30px 30px;

  .month-name {
    position: sticky;
    top: 0;
    font-family: "Circular Std", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

  }

  .dates-in-month {
    display: flex;
  }

  .single-date {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50px;

    span {
      font-family: "Circular Std", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #1B1F414D;
    }

    .horizontal-line {
      height: calc(100vh - 200px);
      width: 2px;
      border-right: 0.56px dashed #1B1F4199;
    }

    .planned-icons {
      position: absolute;
      top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  &:first-child {
    /*margin-top: 87px;*/
  }

  img {
    vertical-align: inherit;
  }
}

.milestones-container {
  margin-top: 80px;
}

.inner-chart {
  background-color: #fff;
}

.scan-refinement {
  border: 1px solid #777777 !important;
}

.highlighted {
  transform: scale(1.03);
  background-image: url("../image/AddCircle.svg");
  background-repeat: no-repeat;
}

.planned-date-checkmark {
  left: -16px;
  position: absolute;
}